import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Article } from "../style/article"

const AboutUs = () => {
  return (
    <Layout>
      <SEO title="About Us | Get Fitness Fluent" />
      <Article style={{ minHeight: "60vh", marginTop: "8rem" }}>
        <h1>About Us</h1>
        <p>
          Our goal at <span className="highlight">get Fitness Fluent</span> is
          to empower people with the information and knowledge to live a
          healthier life. Whether it is trying out a completly new workout
          routine outside their comfort zone or learning about a product that
          migh help them achieve their personal goals. We hope that we at get
          Fitness Fluent are a part of that goal.
        </p>
      </Article>
    </Layout>
  )
}

export default AboutUs
